<template>
  <div class="iso-popup" v-click-outside="closeClickHandler">
    <i @click="closeClickHandler" class="far fa-times-circle"/>
    <span class="iso-popup__title span-2-columns">{{ title }}</span>

    <span class="iso-popup__label">Certification: </span>
    <span>{{ certification }}</span>

    <span class="iso-popup__label">Certificate Number: </span>
    <span>{{ certificateNumber }}</span>

    <span class="iso-popup__label">Registrar: </span>
    <span>{{ registrar }}</span>

    <span class="iso-popup__label span-2-columns">Scope of Registration: </span>
    <div class="iso-popup__scope-content span-2-columns">
        <span
            v-for="(scope, index) in scopes"
            :key="index">
          *{{ scope }}
        </span>
    </div>

    <span class="iso-popup__label">ASSOSIATED ORGANIZATION: </span>
    <span>{{ organization }}</span>

  </div>
</template>

<script>

export default {

  name: 'IsoPopup',

  props: ['title', 'certification', 'certificateNumber', 'registrar', 'scopes', 'organization'],

  methods: {
    closeClickHandler() {
      this.$emit('close-iso');
    },

    directives: {
      'click-outside': {
        bind: function(el, binding, vNode) {
          // Provided expression must evaluate to a function.
          if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) { warn += `Found in component '${compName}'` }

            console.warn(warn)
          }
          // Define Handler and cache it on the element
          const bubble = binding.modifiers.bubble
          const handler = (e) => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
              binding.value(e)
            }
          }
          el.__vueClickOutside__ = handler

          // add Event Listeners
          document.addEventListener('click', handler)
        },

        unbind: function(el, binding) {
          // Remove Event Listeners
          document.removeEventListener('click', el.__vueClickOutside__)
          el.__vueClickOutside__ = null

        }
      }
    },
  }

}

</script>

<style lang="scss" scoped>

.iso-popup {
  display: grid;
  grid-template-columns: 1fr max-content;
  row-gap: 10px;
  padding: 20px;
  background-color: #fff;
  color: #333;
  z-index: 10;
  -webkit-box-shadow: 0 0 7px 0 #dadada, -25px 25px 0px 0px #dadada, -25px 25px 0px 0px #dadada;
  box-shadow: 0 0 7px 0 #dadada, -25px 25px 0px 0px #dadada, -25px 25px 0px 0px #dadada;
  line-height: 1.2em;

  i.fa-times-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 23px;
    color: #d8d8d7;

    &:hover {
      cursor: pointer;
    }
  }

  span {
    padding: 5px;
  }

  .span-2-columns {
    grid-column: 1 / span 2;
  }

  &__title {
    font-weight: 900;
    font-size: 2em;
  }

  &__label {
    font-weight: 900;
  }

  &__scope-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

}

@media only screen and (max-width: 425px) {
  .iso-popup {
    &__label {
      grid-column: 1 / span 2;
    }
  }
}

</style>