<template>

  <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave">
    <ul v-show="list.open" class="sub-items">

      <transition name="showIsoPopup">
        <iso-popup
            v-if="showISO27001Modal"
            @close-iso="hideISO27001"
            title="ISO27001"
            certification="IS0 27001 :2013"
            certificate-number="200234/B/0001/UK/Ro"
            registrar="United Registrar of Systems"
            :scopes="scopesISO27001"
            organization="SCRIPTICS DECISIONS SRL"
        />
      </transition>

      <transition name="showIsoPopup">
        <iso-popup
            v-if="showISO9001Modal"
            @close-iso="hideISO9001"
            title="ISO9001"
            certification="IS0 9001 :2015"
            certificate-number="200234/A0001/UK/Ro"
            registrar="United Registrar of Systems"
            :scopes="scopesISO9001"
            organization="SCRIPTICS DECISIONS SRL"
        />
      </transition>

      <li class="sub-item"
          v-for="(item, index) in list.subnav"
          :key="'item'+index"
          :style="{ backgroundColor: colorSubMenu }">
        <i v-if="item.subnav" class="fas"
           :class="{'fas fa-angle-down':!item.open,
                    'fas fa-angle-up':item.open}"></i>
        <div class="title" @click="item.open = !item.open">
          <a
              @click="subnavClickHandler(item.functionality)"
              :href="item.route">
            {{ item.title }}
          </a>
        </div>
        <DropDown class="subnav" v-if="item.subnav" :list="item" />
      </li>
    </ul>
  </transition>
</template>

<script>
import IsoPopup from "@/components/IsoPopup.vue";

export default {

  name: 'DropDown',

  components: {IsoPopup},

  props: ['list', 'subMenuColor'],

  data() {
    return {
      colorSubMenu: this.subMenuColor,
      showISO27001Modal: false,
      showISO9001Modal: false,
      scopesISO27001: ['System Integration, Network Integration, other software and web services development', 'Cloud Services, Maintenance and Related Products', 'Intellectual Property.'],
      scopesISO9001: ['Design', 'Development (Web Development and Softwaredevelopment)', ' Support Services', 'Managed Services', 'Hosting and Managed, Hosting Services.'],
    }
  },

  methods: {

    enter(el) {
      el.style.height = 'auto';
      const height = getComputedStyle(el).height;
      el.style.height = 0;
      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = height;
      });
    },

    afterEnter(el) {
      el.style.height = 'auto';
    },

    leave(el) {
      el.style.height = getComputedStyle(el).height;
      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      });
    },

    showCookiesPolicy() {
      const panel = this.$showPanel({
        component: "CookiesPanel",
        cssClass: "Panel",
        cssClass: "gray-panel",

        props: {}
      });
    },
    showPrivacyPolicy() {
      const panel = this.$showPanel({
        component: "PrivacyPolicyPanel",
        cssClass: "Panel",
        cssClass: "gray-panel",
        props: {}
      });
    },

    displayISO27001() {
      this.showISO27001Modal = true;
      this.hideISO9001();
    },

    hideISO27001() {
      this.showISO27001Modal = false;
    },

    displayISO9001() {
      this.showISO9001Modal = true;
      this.hideISO27001();
    },

    hideISO9001() {
      this.showISO9001Modal = false;
    },

    subnavClickHandler(functionality) {
      if (functionality === 'cookie') {
        this.showCookiesPolicy();
      }
      if (functionality === 'pPolicy') {
        this.showPrivacyPolicy();
      }

      if (functionality === 'ISO27001') {
        this.displayISO27001();
      }

      if (functionality === 'ISO9001') {
        this.displayISO9001();
      }

    },

  }
}
</script>

<style lang="scss">

.sub-items a, .title a {
  list-style: none;
  text-decoration: none;
  color: #888888;
}

.sub-items  {
  list-style: none;
  text-decoration: none;
  .sub-item {
    position: relative;
    cursor: pointer;

    .subnav {
      padding-left: 20px;
    }
    .title {
      padding: 10px 0;
      text-indent: 20px;
      font-size: 15pt;
      font-weight: 500;
      color: #888888;
    }
    .title:hover {
      opacity: .6;
    }

    i {
      position: absolute;
      top: 12px;
      right: 22px;
    }
  }
}

.expand-enter-active, .expand-leave-active {
  transition: height .5s ease-in-out;
  overflow: hidden;
}

.iso-popup {
  position: absolute;
  bottom: 50%;
}

//ISO Popup transition
.showIsoPopup-enter, .showIsoPopup-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.showIsoPopup-enter-active, .showIsoPopup-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.showIsoPopup-enter-to, .showIsoPopup-leave {
  opacity: 1;
  transform: translateY(0px);
}

</style>
